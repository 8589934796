import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { catchError, map } from "rxjs/operators";
import { environment } from "../../../environments/environment";
import { EncryptionService } from "./encryption.service";

@Injectable({ providedIn: "root" })
export class HttpService {

  constructor(private _http: HttpClient, private router: Router, private encryptionService: EncryptionService) { }

  SERVER_URL = environment.apiUrl;


  private getStoredAdminInfo(): any {
    let storedAdminInfo: any = {};
    const rememberMe = localStorage.getItem('rememberMe') ? true : false;
    const encryptedData = rememberMe ? localStorage.getItem('storedAdminInfo') : sessionStorage.getItem('storedAdminInfo');

    if (encryptedData) {
      try {
        const decryptedData = this.encryptionService.decrypt(encryptedData);
        if (decryptedData) {
          storedAdminInfo = JSON.parse(decryptedData);
        }
      } catch (e) {
        console.error('Error parsing stored admin info:', e);
      }
    }
    return storedAdminInfo;
  }

  httpRequestFunction(url: string, endpoint: string, params: any = null, headersOptions: any = null, method: string) {
    if (method === 'get') {
      return this._http.get(url + endpoint, headersOptions).pipe(
        map((res: any) => {
          let updatedToken = res['token'];
          let storedAdminInfo = this.getStoredAdminInfo();

          if (updatedToken) {
            storedAdminInfo.token = updatedToken;
            const encryptedAdminInfo = this.encryptionService.encrypt(JSON.stringify(storedAdminInfo));
            sessionStorage.setItem('storedAdminInfo', encryptedAdminInfo);
            localStorage.setItem('storedAdminInfo', encryptedAdminInfo);
          }
          return res;
        }),
        catchError(err => {
          if (err.error?.error?.error_code === 'T001') {
            sessionStorage.removeItem('storedAdminInfo');
            localStorage.removeItem('storedAdminInfo');
            this.router.navigate(['/auth/user-login']);
          }
          throw 'Error making an API request: ' + err;
        })
      );
    } else {
      return this._http.post(url + endpoint, params, headersOptions).pipe(
        map((res: any) => {
          console.log(res);
          let updatedToken = res['token'];
          let storedAdminInfo = this.getStoredAdminInfo();

          if (updatedToken) {
            storedAdminInfo.token = updatedToken;
            const encryptedAdminInfo = this.encryptionService.encrypt(JSON.stringify(storedAdminInfo));
            sessionStorage.setItem('storedAdminInfo', encryptedAdminInfo);
            localStorage.setItem('storedAdminInfo', encryptedAdminInfo);
          }
          return res;
        }),
        catchError(err => {
          if (err.error?.error?.error_code === 'T001') {
            sessionStorage.removeItem('storedAdminInfo');
            localStorage.removeItem('storedAdminInfo');
            this.router.navigate(['/auth/user-login']);
          }
          throw 'Error making an API request: ' + err;
        })
      );
    }
  }

  getStoredToken(): string | null {
    let storedAdminInfo = this.getStoredAdminInfo();
    return storedAdminInfo.token || null;
  }
  // getStoredToken(): string | null {
  //   let storedAdminInfo: any;
  //   let rememberMe: boolean;
  //   localStorage.getItem('rememberMe') ? rememberMe = true : rememberMe = false;
  //   rememberMe ? storedAdminInfo = localStorage.getItem('storedAdminInfo') :
  //     storedAdminInfo = sessionStorage.getItem('storedAdminInfo');
  //   storedAdminInfo ? storedAdminInfo = JSON.parse(storedAdminInfo) : storedAdminInfo = {};
  //   if (storedAdminInfo && storedAdminInfo.token) {
  //     return storedAdminInfo.token;
  //   } else {
  //     console.log('http service token', storedAdminInfo.token);
  //     return sessionStorage.getItem('token');
  //   }
  // }

  admin_login(login_info) {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json'); // Assuming you want to set JSON content type
    let headerOptions = { headers: headers };
    let endPoint = 'admin_login';
    let data = login_info;
    let method = 'post';
    return this.httpRequestFunction(this.SERVER_URL, endPoint, data, headerOptions, method);
  }

  user_login(login_info) {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json'); // Assuming you want to set JSON content type
    let headerOptions = { headers: headers };
    let endPoint = 'user_login';
    let data = login_info;
    let method = 'post';
    return this.httpRequestFunction(this.SERVER_URL, endPoint, data, headerOptions, method);
  }

  user_list(info) {
    let headers = new HttpHeaders().set('token', this.getStoredToken());
    headers = headers.set('Content-Type', 'application/json'); // Assuming you want to set JSON content type
    let headerOptions = { headers: headers };
    let endPoint = 'user_list';
    let data = info;
    let method = 'post';
    return this.httpRequestFunction(this.SERVER_URL, endPoint, data, headerOptions, method);
  }
  invite_new_user(info) {
    let headers = new HttpHeaders().set('token', this.getStoredToken());
    headers = headers.set('Content-Type', 'application/json'); // Assuming you want to set JSON content type
    let headerOptions = { headers: headers };
    let endPoint = 'invite_new_user';
    let data = info;
    let method = 'post';
    return this.httpRequestFunction(this.SERVER_URL, endPoint, data, headerOptions, method);
  }
  EditUser(info) {
    let headers = new HttpHeaders().set('token', this.getStoredToken());
    headers = headers.set('Content-Type', 'application/json'); // Assuming you want to set JSON content type
    let headerOptions = { headers: headers };
    let endPoint = 'edit_user';
    let data = info;
    let method = 'post';
    return this.httpRequestFunction(this.SERVER_URL, endPoint, data, headerOptions, method);
  }
  role_list() {
    let headers = new HttpHeaders().set('token', this.getStoredToken());
    headers = headers.set('Content-Type', 'application/json'); // Assuming you want to set JSON content type
    let headerOptions = { headers: headers };
    let endPoint = 'role_list';
    let method = 'get';
    return this.httpRequestFunction(this.SERVER_URL, endPoint, null, headerOptions, method);
  }
  verifyPasswordSetToken(token_info) {
    // let headers = new HttpHeaders().set('token', sessionStorage.getItem('token'));
    let headers = new HttpHeaders();
    let headerOptions = { headers: headers };
    let endPoint = 'user_decrypt_setup_password_data';
    let data = token_info;
    let method = 'post';

    return this.httpRequestFunction(this.SERVER_URL, endPoint, data, headerOptions, method);

  }
  verifyPasswordResetToken(token_info) {
    // let headers = new HttpHeaders().set('token', sessionStorage.getItem('token'));
    let headers = new HttpHeaders();
    let headerOptions = { headers: headers };
    let endPoint = 'user_decrypt_reset_password_data';
    let data = token_info;
    let method = 'post';

    return this.httpRequestFunction(this.SERVER_URL, endPoint, data, headerOptions, method);

  }
  setPassword(password_details) {
    let headers = new HttpHeaders().set('token', localStorage.getItem('setpasswordToken'));
    let headerOptions = { headers: headers };
    let endPoint = 'user_setup_password';
    let data = password_details;
    let method = 'post';

    return this.httpRequestFunction(this.SERVER_URL, endPoint, data, headerOptions, method);

  }
  resetPassword(password_details) {
    let headers = new HttpHeaders().set('token', localStorage.getItem('resetpasswordToken'));
    let headerOptions = { headers: headers };
    let endPoint = 'user_reset_password';
    let data = password_details;
    let method = 'post';

    return this.httpRequestFunction(this.SERVER_URL, endPoint, data, headerOptions, method);

  }
  inviteAccept(acceptInviteData) {
    let headers = new HttpHeaders().set('token', localStorage.getItem('setpasswordToken'));
    let headerOptions = { headers: headers };
    let endPoint = 'user_invite_accept';
    let data = acceptInviteData;
    let method = 'post';

    return this.httpRequestFunction(this.SERVER_URL, endPoint, data, headerOptions, method);

  }
  fetchNodesList(nodes_data) {
    let headers = new HttpHeaders().set('token', this.getStoredToken());
    headers = headers.set('Content-Type', 'application/json'); // Assuming you want to set JSON content type
    let headerOptions = { headers: headers };
    let endPoint = 'user_nodes_list';
    let data = nodes_data;
    let method = 'post';

    return this.httpRequestFunction(this.SERVER_URL, endPoint, data, headerOptions, method);

  }
  fetchWorkspaces() {
    let headers = new HttpHeaders().set('token', this.getStoredToken());
    headers = headers.set('Content-Type', 'application/json'); // Assuming you want to set JSON content type
    let headerOptions = { headers: headers };
    let endPoint = 'workspaces_list';
    let method = 'get';
    return this.httpRequestFunction(this.SERVER_URL, endPoint, null, headerOptions, method);
  }
  fetchWorkspaceChildNodesList(node_id) {
    let headers = new HttpHeaders().set('token', this.getStoredToken());
    headers = headers.set('Content-Type', 'application/json'); // Assuming you want to set JSON content type
    let headerOptions = { headers: headers };
    let endPoint = 'workspace_child_nodes_list';
    let data = node_id;
    let method = 'post';
    return this.httpRequestFunction(this.SERVER_URL, endPoint, data, headerOptions, method);
  }
  fetchParentChildNodes(node_id) {
    let headers = new HttpHeaders().set('token', this.getStoredToken());
    headers = headers.set('Content-Type', 'application/json'); // Assuming you want to set JSON content type
    let headerOptions = { headers: headers };
    let endPoint = 'workspace_parent_siblings_list';
    let data = node_id;
    let method = 'post';
    return this.httpRequestFunction(this.SERVER_URL, endPoint, data, headerOptions, method);
  }
  fetchParentChildNodesList(node_id) {
    let headers = new HttpHeaders().set('token', this.getStoredToken());
    headers = headers.set('Content-Type', 'application/json'); // Assuming you want to set JSON content type
    let headerOptions = { headers: headers };
    let endPoint = 'parent_siblings_list';
    let data = node_id;
    let method = 'post';
    return this.httpRequestFunction(this.SERVER_URL, endPoint, data, headerOptions, method);
  }
  fetchRootNode() {
    let headers = new HttpHeaders().set('token', this.getStoredToken());
    headers = headers.set('Content-Type', 'application/json'); // Assuming you want to set JSON content type
    let headerOptions = { headers: headers };
    let endPoint = 'root_node_info';
    let method = 'get';
    return this.httpRequestFunction(this.SERVER_URL, endPoint, null, headerOptions, method);
  }
  createFolder(folderData) {
    let headers = new HttpHeaders().set('token', this.getStoredToken());
    headers = headers.set('Content-Type', 'application/json'); // Assuming you want to set JSON content type
    let headerOptions = { headers: headers };
    let endPoint = 'create_folder';
    let data = folderData;
    let method = 'post';
    return this.httpRequestFunction(this.SERVER_URL, endPoint, data, headerOptions, method);
  }
  moveNode(folderData) {
    let headers = new HttpHeaders().set('token', this.getStoredToken());
    headers = headers.set('Content-Type', 'application/json'); // Assuming you want to set JSON content type
    let headerOptions = { headers: headers };
    let endPoint = 'node_move';
    let data = folderData;
    let method = 'post';
    return this.httpRequestFunction(this.SERVER_URL, endPoint, data, headerOptions, method);
  }
  renameNode(folderData) {
    let headers = new HttpHeaders().set('token', this.getStoredToken());
    headers = headers.set('Content-Type', 'application/json'); // Assuming you want to set JSON content type
    let headerOptions = { headers: headers };
    let endPoint = 'node_rename';
    let data = folderData;
    let method = 'post';
    return this.httpRequestFunction(this.SERVER_URL, endPoint, data, headerOptions, method);
  }
  fetchUserInfo(user_id) {
    let headers = new HttpHeaders().set('token', this.getStoredToken());
    headers = headers.set('Content-Type', 'application/json'); // Assuming you want to set JSON content type
    let headerOptions = { headers: headers };
    let endPoint = 'user_info';
    let data = user_id;
    let method = 'post';

    return this.httpRequestFunction(this.SERVER_URL, endPoint, data, headerOptions, method);

  }
  getFailedFilesInformation(aspera_transfer_id) {
    let headers = new HttpHeaders().set('token', this.getStoredToken());
    headers = headers.set('Content-Type', 'application/json'); // Assuming you want to set JSON content type
    let headerOptions = { headers: headers };
    let endPoint = 'get_failed_files_information';
    let data = aspera_transfer_id;

    let method = 'post';

    return this.httpRequestFunction(this.SERVER_URL, endPoint, data, headerOptions, method);
  }

  deleteNode(folderData) {
    let headers = new HttpHeaders().set('token', this.getStoredToken());
    headers = headers.set('Content-Type', 'application/json'); // Assuming you want to set JSON content type
    let headerOptions = { headers: headers };
    let endPoint = 'delete_node';
    let data = folderData;
    let method = 'post';

    return this.httpRequestFunction(this.SERVER_URL, endPoint, data, headerOptions, method);

  }
  clientLogo(info) {
    let headers = new HttpHeaders();
    let headerOptions = { headers: headers };
    let endPoint = 'client_logo';
    let data = info;
    let method = 'post';

    return this.httpRequestFunction(this.SERVER_URL, endPoint, data, headerOptions, method);

  }
  intiateForgotPassword(info) {
    let headers = new HttpHeaders();
    let headerOptions = { headers: headers };
    let endPoint = 'user_forgot_password';
    let data = info;
    let method = 'post';

    return this.httpRequestFunction(this.SERVER_URL, endPoint, data, headerOptions, method);

  }
  fetchListofRestrictedUserAccess(info) {
    let headers = new HttpHeaders().set('token', this.getStoredToken());
    let headerOptions = { headers: headers };
    let endPoint = 'get_user_access_list_for_specific_node';
    let data = info;
    let method = 'post';

    return this.httpRequestFunction(this.SERVER_URL, endPoint, data, headerOptions, method);

  }
  createRestrictedAccess(info) {
    let headers = new HttpHeaders().set('token', this.getStoredToken());
    let headerOptions = { headers: headers };
    let endPoint = 'create_restricted_access';
    let data = info;
    let method = 'post';

    return this.httpRequestFunction(this.SERVER_URL, endPoint, data, headerOptions, method);

  }
  removeRestrictedAccess(info) {
    let headers = new HttpHeaders().set('token', this.getStoredToken());
    let headerOptions = { headers: headers };
    let endPoint = 'delete_restricted_access_of_user';
    let data = info;
    let method = 'post';

    return this.httpRequestFunction(this.SERVER_URL, endPoint, data, headerOptions, method);

  }
  updateRestrictedAccess(info) {
    let headers = new HttpHeaders().set('token', this.getStoredToken());
    let headerOptions = { headers: headers };
    let endPoint = 'update_restricted_access_of_user';
    let data = info;
    let method = 'post';

    return this.httpRequestFunction(this.SERVER_URL, endPoint, data, headerOptions, method);

  }
  fetchSharedWithMeNodes() {
    let headers = new HttpHeaders().set('token', this.getStoredToken());
    let headerOptions = { headers: headers };
    let endPoint = 'shared_with_me_nodes';
    let method = 'get';

    return this.httpRequestFunction(this.SERVER_URL, endPoint, null, headerOptions, method);

  }
  fetchRestrictedAccessChildNodesList(info) {
    let headers = new HttpHeaders().set('token', this.getStoredToken());
    let headerOptions = { headers: headers };
    let endPoint = 'restricted_access_child_nodes';
    let data = info;
    let method = 'post';

    return this.httpRequestFunction(this.SERVER_URL, endPoint, data, headerOptions, method);
  }
  resend_invite(info: FormData) {
    let headers = new HttpHeaders().set('token', this.getStoredToken());
    let headerOptions = { headers: headers };
    let endPoint = 'resend_invite';
    let data = info;
    let method = 'post';
    return this.httpRequestFunction(this.SERVER_URL, endPoint, data, headerOptions, method);
  }
  cancel_invite(info: FormData) {
    let headers = new HttpHeaders().set('token', this.getStoredToken());
    let headerOptions = { headers: headers };
    let endPoint = 'cancel_invite';
    let data = info;
    let method = 'post';
    return this.httpRequestFunction(this.SERVER_URL, endPoint, data, headerOptions, method);
  }

  EditProfile(info) {
    let headers = new HttpHeaders().set('token', this.getStoredToken());
    headers = headers.set('Content-Type', 'application/json'); // Assuming you want to set JSON content type
    let headerOptions = { headers: headers };
    let endPoint = 'edit_user';
    let data = info;
    let method = 'post';
    return this.httpRequestFunction(this.SERVER_URL, endPoint, data, headerOptions, method);
  }

  deleteUser(info) {
    let headers = new HttpHeaders().set('token', this.getStoredToken());
    let headerOptions = { headers: headers };
    let endPoint = 'delete_user';
    let data = info;
    let method = 'post';

    return this.httpRequestFunction(this.SERVER_URL, endPoint, data, headerOptions, method);

  }

  unBlockUser(info) {
    let headers = new HttpHeaders().set('token', this.getStoredToken());
    let headerOptions = { headers: headers };
    let endPoint = 'unblock_user';
    let data = info;
    let method = 'post';

    return this.httpRequestFunction(this.SERVER_URL, endPoint, data, headerOptions, method);

  }

  fetchUserProfileInfo() {
    let headers = new HttpHeaders().set('token', this.getStoredToken());
    headers = headers.set('Content-Type', 'application/json'); // Assuming you want to set JSON content type
    let headerOptions = { headers: headers };
    let endPoint = 'view_profile';
    // let data = info;
    let method = 'post';
    return this.httpRequestFunction(this.SERVER_URL, endPoint, null, headerOptions, method);
  }

  EditUserProfile(info) {
    let headers = new HttpHeaders().set('token', this.getStoredToken());
    headers = headers.set('Content-Type', 'application/json'); // Assuming you want to set JSON content type
    let headerOptions = { headers: headers };
    let endPoint = 'edit_profile';
    let data = info;
    let method = 'post';
    return this.httpRequestFunction(this.SERVER_URL, endPoint, data, headerOptions, method);
  }
}

