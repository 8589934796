import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
    providedIn: 'root'
})
export class EncryptionService {
    private secretKey: string = 'Z426aTRQW00KAuMrBweGbj3jKpl3gJLoZ';

    encrypt(data: string): string {
        try {
            const encrypted = CryptoJS.AES.encrypt(JSON.stringify(data), this.secretKey).toString();
            return encodeURIComponent(encrypted);
        } catch (e) {
            console.error('Error encrypting data:', e);
            return '';
        }
    }

    decrypt(data: string): any {
        try {
            const decodedData = decodeURIComponent(data);
            const decrypted = CryptoJS.AES.decrypt(decodedData, this.secretKey);
            const decryptedString = decrypted.toString(CryptoJS.enc.Utf8);
            return JSON.parse(decryptedString);
        } catch (e) {
            console.error('Error decrypting data:', e);
            return null;
        }
    }
}
