import { Injectable } from "@angular/core";
import { HttpService } from "./http.service";
import { map } from "rxjs/operators";
import { BehaviorSubject } from "rxjs";

@Injectable({ providedIn: 'root' })
export class ClientUserService {
  constructor(private httpService: HttpService) { }

  updateUserSessionInfo: any = new BehaviorSubject({});
  validNodeTypes = [
    { node_name: 'mp4', node_type: 'media' },
    { node_name: 'mkv', node_type: 'media' },
    { node_name: 'avi', node_type: 'media' },
    { node_name: 'mov', node_type: 'media' },
    { node_name: 'wmv', node_type: 'media' },
    { node_name: 'png', node_type: 'media' },
    { node_name: 'jpg', node_type: 'media' },
    { node_name: 'jpeg', node_type: 'media' },
    
    // Non-media types
    { node_name: 'zip', node_type: 'non-media' },
    { node_name: 'rar', node_type: 'non-media' },
    { node_name: 'html', node_type: 'non-media' },
    { node_name: 'js', node_type: 'non-media' },
    { node_name: 'json', node_type: 'non-media' },
    { node_name: 'java', node_type: 'non-media' },
    { node_name: 'md', node_type: 'non-media' },
    { node_name: 'pdf', node_type: 'non-media' },
    { node_name: 'txt', node_type: 'non-media' },
    { node_name: 'doc', node_type: 'non-media' },
    { node_name: 'docx', node_type: 'non-media' },
    { node_name: 'xls', node_type: 'non-media' },
    { node_name: 'xlsx', node_type: 'non-media' },
    { node_name: 'wpd', node_type: 'non-media' },
    { node_name: 'csv', node_type: 'non-media' },
    { node_name: 'ics', node_type: 'non-media' },
    { node_name: '7z', node_type: 'non-media' },
    { node_name: 'tar', node_type: 'non-media' },
    { node_name: 'gz', node_type: 'non-media' },
    { node_name: 'bz2', node_type: 'non-media' },
    { node_name: 'xz', node_type: 'non-media' },
    { node_name: 'css', node_type: 'non-media' },
    { node_name: 'ts', node_type: 'non-media' },
    { node_name: 'py', node_type: 'non-media' },
    { node_name: 'cpp', node_type: 'non-media' },
    { node_name: 'c', node_type: 'non-media' },
    { node_name: 'h', node_type: 'non-media' },
    { node_name: 'cs', node_type: 'non-media' },
    { node_name: 'rb', node_type: 'non-media' },
    { node_name: 'xml', node_type: 'non-media' },
    { node_name: 'sql', node_type: 'non-media' },
    { node_name: 'log', node_type: 'non-media' },
    { node_name: 'epub', node_type: 'non-media' },
    { node_name: 'odt', node_type: 'non-media' },
    { node_name: 'rtf', node_type: 'non-media' },
    { node_name: 'ppt', node_type: 'non-media' },
    { node_name: 'pptx', node_type: 'non-media' },
    { node_name: 'ods', node_type: 'non-media' },
    { node_name: 'azw', node_type: 'non-media' },
    { node_name: 'mobi', node_type: 'non-media' },
    { node_name: 'wps', node_type: 'non-media' },
    { node_name: 'odp', node_type: 'non-media' },
    { node_name: 'sh', node_type: 'non-media' },
    { node_name: 'bat', node_type: 'non-media' },
    { node_name: 'php', node_type: 'non-media' },
    { node_name: 'go', node_type: 'non-media' },
    { node_name: 'swift', node_type: 'non-media' },
    { node_name: 'dart', node_type: 'non-media' },
    { node_name: 'yml', node_type: 'non-media' },
    { node_name: 'yaml', node_type: 'non-media' },
    { node_name: 'env', node_type: 'non-media' },
    { node_name: 'conf', node_type: 'non-media' },
    { node_name: 'ini', node_type: 'non-media' },
    { node_name: 'db', node_type: 'non-media' },
    { node_name: 'sqlite', node_type: 'non-media' },
    { node_name: 'dat', node_type: 'non-media' },
    { node_name: 'bak', node_type: 'non-media' },
    { node_name: 'mat', node_type: 'non-media' },
    { node_name: 'sas', node_type: 'non-media' },
    { node_name: 'sav', node_type: 'non-media' },
    { node_name: 'cab', node_type: 'non-media' },
    { node_name: 'iso', node_type: 'non-media' },
    { node_name: 'aac', node_type: 'non-media' },
    { node_name: 'wma', node_type: 'non-media' },
    { node_name: 'aiff', node_type: 'non-media' },
    { node_name: 'wav', node_type: 'non-media' },
    { node_name: 'flac', node_type: 'non-media' },
    { node_name: 'm4a', node_type: 'non-media' },
    { node_name: 'opus', node_type: 'non-media' },
    { node_name: 'oga', node_type: 'non-media' },
    { node_name: 'mp3', node_type: 'non-media' },
    { node_name: 'amr', node_type: 'non-media' },
    { node_name: 'mid', node_type: 'non-media' },
    { node_name: 'midi', node_type: 'non-media' },
    { node_name: 'ra', node_type: 'non-media' },
    { node_name: 'vox', node_type: 'non-media' },
    { node_name: 'gsm', node_type: 'non-media' },
    { node_name: 'spx', node_type: 'non-media' },
    { node_name: 'aax', node_type: 'non-media' },
    { node_name: 'alac', node_type: 'non-media' },
    { node_name: '8svx', node_type: 'non-media' },
    { node_name: 's3m', node_type: 'non-media' },
    { node_name: 'ac3', node_type: 'non-media' },
    { node_name: 'dts', node_type: 'non-media' },
    { node_name: 'wv', node_type: 'non-media' },
    { node_name: 'ape', node_type: 'non-media' },
    { node_name: 'au', node_type: 'non-media' },
    { node_name: 'snd', node_type: 'non-media' },
    { node_name: 'kar', node_type: 'non-media' },
    { node_name: 'it', node_type: 'non-media' },
    { node_name: 'xm', node_type: 'non-media' },
    { node_name: 'vgm', node_type: 'non-media' },
    { node_name: 'pls', node_type: 'non-media' },
    { node_name: 'm3u', node_type: 'non-media' },
    { node_name: 'asx', node_type: 'non-media' },
    { node_name: 'aa', node_type: 'non-media' },
    { node_name: 'cda', node_type: 'non-media' },
    { node_name: 'sf2', node_type: 'non-media' },
    { node_name: 'rmi', node_type: 'non-media' },
    { node_name: 'tta', node_type: 'non-media' },
    { node_name: 'voc', node_type: 'non-media' },
    { node_name: 'bwf', node_type: 'non-media' },
    { node_name: 'mpc', node_type: 'non-media' },

    // Additional media formats
    { node_name: 'mpeg', node_type: 'media' },
    { node_name: 'mpg', node_type: 'media' },
    { node_name: 'm4v', node_type: 'media' },
    { node_name: '3gp', node_type: 'media' },
    { node_name: '3g2', node_type: 'media' },
    { node_name: 'mxf', node_type: 'media' },
    { node_name: 'mts', node_type: 'media' },
    { node_name: 'm2ts', node_type: 'media' },
    { node_name: 'ts', node_type: 'media' },
    { node_name: 'vob', node_type: 'media' },
    { node_name: 'ogv', node_type: 'media' },
    { node_name: 'ogg', node_type: 'media' },
    { node_name: 'rm', node_type: 'media' },
    { node_name: 'rmvb', node_type: 'media' },
    { node_name: 'asf', node_type: 'media' },
    { node_name: 'dv', node_type: 'media' },
    { node_name: 'f4v', node_type: 'media' },
    { node_name: 'h261', node_type: 'media' },
    { node_name: 'h263', node_type: 'media' },
    { node_name: 'h264', node_type: 'media' },
    { node_name: 'hevc', node_type: 'media' },
    { node_name: 'vp8', node_type: 'media' },
    { node_name: 'vp9', node_type: 'media' },
    { node_name: 'av1', node_type: 'media' },
    { node_name: 'avc', node_type: 'media' },
    { node_name: 'avchd', node_type: 'media' },
    { node_name: 'flv', node_type: 'media' },
    { node_name: 'm2v', node_type: 'media' },
    { node_name: 'm4p', node_type: 'media' },
    { node_name: 'm4v', node_type: 'media' },
    { node_name: 'm2ts', node_type: 'media' },
    { node_name: 'm2', node_type: 'media' },
    { node_name: 'webp', node_type: 'media' },
    { node_name: 'avif', node_type: 'media' },
    { node_name: 'jfif', node_type: 'media' },
    { node_name: 'pjpeg', node_type: 'media' },
    { node_name: 'pjp', node_type: 'media' },
    { node_name: 'svg', node_type: 'media' },
    { node_name: 'webm', node_type: 'media' },
    { node_name: 'mp2', node_type: 'media' },
    { node_name: 'braw', node_type: 'media' },
    { node_name: 'dng', node_type: 'media' },
    { node_name: 'vrb', node_type: 'media' },
    { node_name: 'bmp', node_type: 'media' },
    { node_name: 'dib', node_type: 'media' },
    { node_name: 'tiff', node_type: 'media' },
    { node_name: 'tif', node_type: 'media' },
    { node_name: 'psd', node_type: 'media' },
    { node_name: 'xcf', node_type: 'media' },
    { node_name: 'raw', node_type: 'media' },
    { node_name: 'cr2', node_type: 'media' },
    { node_name: 'arw', node_type: 'media' },
    { node_name: 'orf', node_type: 'media' },
    { node_name: 'rw2', node_type: 'media' },
    { node_name: 'amv', node_type: 'media' },
    { node_name: 'divx', node_type: 'media' },
    { node_name: 'swf', node_type: 'media' },
    { node_name: 'rtmp', node_type: 'media' },
    { node_name: 'mms', node_type: 'media' },
    { node_name: 'ico', node_type: 'media' },
    { node_name: 'eps', node_type: 'media' },
    { node_name: 'ai', node_type: 'media' },
    { node_name: 'obj', node_type: 'media' },
    { node_name: 'stl', node_type: 'media' },
    
    
];

isMediaType(nodeType: string): any {
    return this.validNodeTypes.some(type => type.node_name === nodeType && type.node_type === 'media');
}

isNonMediaType(nodeType: string): any {
    return this.validNodeTypes.some(type => type.node_name === nodeType && type.node_type === 'non-media');
}

isVideoType(nodeType: string): boolean {
  if (!nodeType) return false;
  
  const videoExtensions = [
    'mp4', 'mkv', 'avi', 'mov', 'wmv', 'mpeg', 'mpg', 'm4v', '3gp', '3g2', 
    'mxf', 'mts', 'm2ts', 'ts', 'vob', 'ogv', 'ogg', 'rm', 'rmvb', 'asf', 
    'dv', 'f4v', 'flv', 'webm', 'divx', 'h264', 'hevc'
  ];
  
  return videoExtensions.includes(nodeType.toLowerCase());
}

isImageType(nodeType: string): boolean {
  if (!nodeType) return false;
  
  const imageExtensions = [
    'png', 'jpg', 'jpeg', 'gif', 'bmp', 'webp', 'tiff', 'tif', 'svg', 
    'jfif', 'pjpeg', 'pjp', 'avif', 'ico', 'dib', 'heif', 'heic' ,'psd'
  ];
  
  return imageExtensions.includes(nodeType.toLowerCase());
}

private iconMap: { [key: string]: string } = {
  mp4: 'mdi-filmstrip',
  mkv: 'mdi-filmstrip',
  avi: 'mdi-filmstrip',
  mov: 'mdi-filmstrip',
  wmv: 'mdi-filmstrip',
  mpeg: 'mdi-filmstrip',
  mpg: 'mdi-filmstrip',
  m4v: 'mdi-filmstrip',
  '3gp': 'mdi-filmstrip',
  '3g2': 'mdi-filmstrip',
  mxf: 'mdi-filmstrip',
  mts: 'mdi-filmstrip',
  m2ts: 'mdi-filmstrip',
  vob: 'mdi-filmstrip',
  ogv: 'mdi-filmstrip',
  ogg: 'mdi-filmstrip',
  rm: 'mdi-filmstrip',
  rmvb: 'mdi-filmstrip',
  asf: 'mdi-filmstrip',
  dv: 'mdi-filmstrip',
  f4v: 'mdi-filmstrip',
  h261: 'mdi-filmstrip',
  h263: 'mdi-filmstrip',
  h264: 'mdi-filmstrip',
  hevc: 'mdi-filmstrip',
  vp8: 'mdi-filmstrip',
  vp9: 'mdi-filmstrip',
  av1: 'mdi-filmstrip',
  avc: 'mdi-filmstrip',
  avchd: 'mdi-filmstrip',
  flv: 'mdi-filmstrip',
  m2v: 'mdi-filmstrip',
  m4p: 'mdi-filmstrip',
  m2: 'mdi-filmstrip',
  webm: 'mdi-filmstrip',
  mp2: 'mdi-filmstrip',
  braw: 'mdi-filmstrip',
  dng: 'mdi-filmstrip',
  vrb: 'mdi-filmstrip',
  amv: 'mdi-filmstrip',
  divx: 'mdi-filmstrip',
  swf: 'mdi-filmstrip',
  rtmp: 'mdi-filmstrip',
  mms: 'mdi-filmstrip',
  bmp: 'mdi-file-image',
  dib: 'mdi-file-image',
  tiff: 'mdi-file-image',
  tif: 'mdi-file-image',
  psd: 'mdi-file-image',
  xcf: 'mdi-file-image',
  raw: 'mdi-file-image',
  cr2: 'mdi-file-image',
  arw: 'mdi-file-image',
  orf: 'mdi-file-image',
  rw2: 'mdi-file-image',
  ico: 'mdi-file-image',
  eps: 'mdi-vector-combine',
  ai: 'mdi-vector-square',
  zip: 'mdi-folder-zip-outline',
  '7z': 'mdi-folder-zip-outline',
  rar: 'mdi-folder-zip-outline',
  html: 'mdi-language-html5',
  js: 'mdi-nodejs',
  json: 'mdi-code-json',
  java: 'mdi-language-java',
  md: 'mdi-markdown',
  pdf: 'mdi-file-pdf-box',
  png: 'mdi-file-image',
  jpg: 'mdi-file-image',
  jpeg: 'mdi-file-image',
  webp: 'mdi-file-image',
  avif: 'mdi-file-image',
  jfif: 'mdi-file-image',
  pjpeg: 'mdi-file-image',
  pjp: 'mdi-file-image',
  svg: 'mdi-file-image',
  txt: 'mdi-file-document-outline',
  doc: 'mdi-file-document-outline',
  docx: 'mdi-file-document-outline',
  xls: 'mdi-file-excel-outline',
  xlsx: 'mdi-file-excel-outline',
  wpd: 'mdi-file-word-box-outline',
  csv: 'mdi-file-delimited',
  ics: 'mdi-calendar',
  tar: 'mdi-folder-zip-outline',
  gz: 'mdi-folder-zip-outline',
  bz2: 'mdi-folder-zip-outline',
  xz: 'mdi-folder-zip-outline',
  css: 'mdi-language-css3',
  ts: 'mdi-language-typescript',
  py: 'mdi-language-python',
  cpp:'mdi-language-cpp',
  c:'mdi-language-cpp',
   h: 'mdi-language-cpp',
  cs: 'mdi-language-csharp',
  rb: 'mdi-language-ruby',
  xml: 'mdi-code-tags',
  sql: 'mdi-database',
  log: 'mdi-file-document-outline',
  epub: 'mdi-book-open-page-variant-outline',
  odt: 'mdi-file-document-outline',
  rtf: 'mdi-file-document-edit-outline',
  ppt: 'mdi-file-powerpoint-outline',
  pptx: 'mdi-file-powerpoint-outline',
  ods: 'mdi-file-excel-outline',
  aac: 'mdi-music',
  wma: 'mdi-music',
  aiff: 'mdi-music-box-outline',
  wav: 'mdi-music-circle-outline',
  flac: 'mdi-music-box-multiple',
  m4a: 'mdi-music-note',
  opus: 'mdi-music-note-outline',
  oga: 'mdi-music-circle',
  azw: 'mdi-book-outline',
  mobi: 'mdi-book',
  wps: 'mdi-file-word-outline',
  odp: 'mdi-file-powerpoint-outline',
  sh: 'mdi-console',
  bat: 'mdi-file-code-outline',
  php: 'mdi-language-php',
  go: 'mdi-language-go',
  swift: 'mdi-language-swift',
  dart: 'mdi-language-dart',
  yml: 'mdi-code-json',
  yaml: 'mdi-code-json',
  env: 'mdi-settings-outline',
  conf: 'mdi-cog-outline',
  ini: 'mdi-file-cog-outline',
  cfg: 'mdi-file-cog',
  db: 'mdi-database',
  sqlite: 'mdi-database-outline',
  dat: 'mdi-database-edit-outline',
  bak: 'mdi-database-arrow-down-outline',
  mat: 'mdi-chart-scatter-plot',
  sas: 'mdi-file-chart-outline',
  sav: 'mdi-file-chart',
  cab: 'mdi-archive',
  iso: 'mdi-disc',
  mp3: 'mdi-music',
  amr: 'mdi-microphone',
  mid: 'mdi-music-note',
  midi: 'mdi-music-note',
  ra: 'mdi-music-circle',
  vox: 'mdi-headset',
  gsm: 'mdi-phone',
  spx: 'mdi-speaker',
  caf: 'mdi-apple',
  alac: 'mdi-apple',
  aax: 'mdi-book-music',
  '8svx': 'mdi-voice',
  s3m: 'mdi-music-note-outline',
  ac3: 'mdi-surround-sound',
  dts: 'mdi-speaker-surround',
  wv: 'mdi-tune',
  ape: 'mdi-music-box',
  au: 'mdi-music-note',
  snd: 'mdi-volume-high',
  kar: 'mdi-microphone-variant',
  it: 'mdi-gamepad-variant',
  xm: 'mdi-music-note-outline',
  mod: 'mdi-music-box-outline',
  vgm: 'mdi-gamepad',
  pls: 'mdi-playlist-music',
  m3u: 'mdi-playlist-music-outline',
  asx: 'mdi-play-network',
  aa: 'mdi-book-music',
  cda: 'mdi-disc',
  sf2: 'mdi-piano',
  rmi: 'mdi-music-note',
  tta: 'mdi-music-box-outline',
  voc: 'mdi-voice',
  bwf: 'mdi-radio',
  mpc: 'mdi-music-box'



};

getIcon(nodeType: string): string {
  return this.iconMap[nodeType] || 'mdi-file-outline';
}


  Login(login_info) {
    return this.httpService.user_login(login_info).pipe(map(res => res));
  }
  UserList(form_info) {
    return this.httpService.user_list(form_info).pipe(map(res => res));
  }
  RoleList() {
    return this.httpService.role_list().pipe(map(res => res));
  }
  AddNewUser(form_info) {
    return this.httpService.invite_new_user(form_info).pipe(map(res => res));
  }
  EditUser(form_info) {
    return this.httpService.EditUser(form_info).pipe(map(res => res));
  }
  verifyPasswordSetToken(token_info) {
    return this.httpService.verifyPasswordSetToken(token_info)
      .pipe(map(
        res => res
      ));
  }
  verifyPasswordResetToken(token_info) {
    return this.httpService.verifyPasswordResetToken(token_info)
      .pipe(map(
        res => res
      ));
  }
  setPassword(password_details) {
    return this.httpService.setPassword(password_details)
      .pipe(map(
        res => res
      ));
  }
  resetPassword(password_details) {
    return this.httpService.resetPassword(password_details)
      .pipe(map(
        res => res
      ));
  }
  fetchNodesList(nodes_data) {
    return this.httpService.fetchNodesList(nodes_data)
      .pipe(map(
        res => res
      ));
  }
  fetchWorkspaces() {
    return this.httpService.fetchWorkspaces()
      .pipe(map(
        res => res
      ));
  }
  fetchWorkspaceChildNodesList(node_id) {
    return this.httpService.fetchWorkspaceChildNodesList(node_id)
      .pipe(map(
        res => res
      ));
  }
  fetchParentChildNodes(node_id) {
    return this.httpService.fetchParentChildNodes(node_id)
      .pipe(map(
        res => res
      ));
  }
  fetchParentChildNodesList(hashed_node_id) {
    return this.httpService.fetchParentChildNodesList(hashed_node_id)
      .pipe(map(
        res => res
      ));
  }
  fetchRootNode() {
    return this.httpService.fetchRootNode()
      .pipe(map(
        res => res
      ));
  }
  inviteAccept(acceptInviteData) {
    return this.httpService.inviteAccept(acceptInviteData)
      .pipe(map(
        res => res
      ));
  }
  createFolder(folderData) {
    return this.httpService.createFolder(folderData)
      .pipe(map(
        res => res
      ));
  }
  moveNode(folderData) {
    return this.httpService.moveNode(folderData)
      .pipe(map(
        res => res
      ));
  }
  renameNode(folderData) {
    return this.httpService.renameNode(folderData)
      .pipe(map(
        res => res
      ));
  }
  fetchUserInfo(user_id) {
    return this.httpService.fetchUserInfo(user_id)
      .pipe(map(
        res => res
      ));
  }

  getFailedFilesInformation(aspera_transfer_id) {
    return this.httpService.getFailedFilesInformation(aspera_transfer_id)
      .pipe(map(
        res => res
      ));

  }

  deleteNode(folderData) {
    return this.httpService.deleteNode(folderData)
      .pipe(map(
        res => res
      ));
  }
  clientLogo(info) {
    return this.httpService.clientLogo(info)
      .pipe(map(
        res => res
      ));
  }
  intiateForgotPassword(info) {
    return this.httpService.intiateForgotPassword(info)
      .pipe(map(
        res => res
      ));
  }
  fetchListofRestrictedUserAccess(info) {
    return this.httpService.fetchListofRestrictedUserAccess(info)
      .pipe(map(
        res => res
      ));
  }
  createRestrictedAccess(info) {
    return this.httpService.createRestrictedAccess(info)
      .pipe(map(
        res => res
      ));
  }
  removeRestrictedAccess(info) {
    return this.httpService.removeRestrictedAccess(info)
      .pipe(map(
        res => res
      ));
  }
  updateRestrictedAccess(info) {
    return this.httpService.updateRestrictedAccess(info)
      .pipe(map(
        res => res
      ));
  }
  fetchSharedWithMeNodes() {
    return this.httpService.fetchSharedWithMeNodes()
      .pipe(map(
        res => res
      ));
  }
  fetchRestrictedAccessChildNodesList(info) {
    return this.httpService.fetchRestrictedAccessChildNodesList(info)
      .pipe(map(
        res => res
      ));
  }
  resend_invite(formData: FormData) {
    return this.httpService.resend_invite(formData).pipe(map(res => res));
  }
  cancel_invite(formData: FormData) {
    return this.httpService.cancel_invite(formData).pipe(map(res => res));
  }

  EditProfile(form_info) {
    return this.httpService.EditProfile(form_info).pipe(map(res => res));
  }


  getUserSessionInfo() {

    return this.updateUserSessionInfo;

  }

  setUserSessionInfo(sessionInfo) {

    this.updateUserSessionInfo.next({
      'session_info': sessionInfo
    });

  }

  deleteUser(form_info) {
    return this.httpService.deleteUser(form_info).pipe(map(res => res));
  }

  unBlockUser(form_info) {
    return this.httpService.unBlockUser(form_info).pipe(map(res => res));
  }

  fetchUserProfileInfo() {
    return this.httpService.fetchUserProfileInfo().pipe(map(res => res));
  }
  EditUserProfile(form_info) {
    return this.httpService.EditUserProfile(form_info).pipe(map(res => res));
  }

  getPreviewNodeInfo(form_info) {
    return this.httpService.getPreviewNodeInfo(form_info).pipe(map(res => res));
  }

  getDashboardStats(timeRange: string = 'week', startDate?: string, endDate?: string) {
    return this.httpService.getDashboardStats(timeRange, startDate, endDate)
      .pipe(map(res => res));
  }

  getRecentTransfers(page: number = 1) {
    console.log('getRecentTransfers', page);
    return this.httpService.getRecentTransfers(page)
      .pipe(map(res => res));
  }

  exportUsers(options: any) {
    return this.httpService.exportUsers(options);
  }

  exportBilling(options: any) {
    return this.httpService.exportBilling(options);
  }
}
