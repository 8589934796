import { Component, OnInit, Output, EventEmitter, Inject, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { environment } from '../../../environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { LanguageService } from '../../core/services/language.service';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { Observable, map } from 'rxjs';
import { changesLayout } from 'src/app/store/layouts/layout.actions';
import { getLayoutMode } from 'src/app/store/layouts/layout.selector';
import { RootReducerState } from 'src/app/store';
import { SocketClientService } from 'src/app/core/services/socket-client.service';
import { changeMode } from 'src/app/store/layouts/layout.actions';
import { StorageService } from 'src/app/core/services/storage.service';
import { EncryptionService } from 'src/app/core/services/encryption.service';
import { ClientUserService } from 'src/app/core/services/client-user-service';


@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})

/**
 * Topbar component
 */
export class TopbarComponent implements OnInit {
  mode: any
  element: any;
  cookieValue: any;
  flagvalue: any;
  countryName: any;
  valueset: any;
  theme: any;
  layout: string;
  dataLayout$: Observable<string>;
  rememberMe: boolean;
  loginRoute: string = '';
  storedAdminInfo: any;
  onlineMachineList: any = [];
  inProgressTransferList: any = [];
  connectedMachineName: string = '';
  connectionStatus: string = 'Not Connected';
  currentMode: string;
  connectedMachineIdentifier: string = '';
  userId: any;
  firstName: any;
  lastName: any;
  // Define layoutMode as a property

  constructor(@Inject(DOCUMENT) private document: any, private router: Router,
    public languageService: LanguageService, private ClientUserService: ClientUserService,
    public translate: TranslateService,private route: ActivatedRoute,private cdr: ChangeDetectorRef,
    public _cookiesService: CookieService, public store: Store<RootReducerState>, private SocketClientService: SocketClientService, private storageService: StorageService, private encryptionService: EncryptionService) {

    console.warn('******************************************TOP BAR CONSTRUCTOR*************************************************************');


    this.store.select(getLayoutMode).subscribe((mode) => {
      this.currentMode = mode;
    });

    //update user firstname & last name
    this.ClientUserService.getUserSessionInfo().subscribe((sessionInfo) => {
      this.storedAdminInfo = sessionInfo.session_info;
      console.log('*****this.storedAdminInfo', this.storedAdminInfo)
    });


    var self = this;

    this.SocketClientService.getUpdatedOnlineMachine().subscribe((res: any) => {

      console.log('Machine information received : ', res);
      console.warn('******************************************NEW MACHINE UPDATE*************************************************************');


      if (Object.keys(res).length == 0) {
        return;
      }

      var obj = this.onlineMachineList.filter(function (machine) {
        return machine.machineIdentifier == res.machineIdentifier;
      });

      if ((obj.length == 0 || obj == null)) {
        console.warn('******************************************NEW MACHINE FOUND*************************************************************');

        this.onlineMachineList.push(res);
        console.log(this.onlineMachineList);
      }


      const existingMachineIdentifier = this._cookiesService.get('machineIdentifier');
      console.log(existingMachineIdentifier + ' == ' + res.machineIdentifier);
      if (existingMachineIdentifier == res.machineIdentifier) {
        self.connectToAMachine(res.machineName, res.machineIdentifier);
      } else {

        // Try to connect to a machine if not connected to any other machine
        if(this.connectionStatus == 'Not Connected' && this.connectedMachineIdentifier == ''){
          console.warn('Machine not connected. Trying to connect to the available machine')
          self.connectToAMachine(res.machineName, res.machineIdentifier);
        }

      }

    });

    this.SocketClientService.getConnectedMachineIdentifier().subscribe((res) => {


      if (Object.keys(res).length == 0) {
        return;
      }
      this.connectedMachineIdentifier = res['machineIdentifier'];
      this.connectedMachineName = res['machineName'];
      if (this.connectedMachineName && this.connectedMachineIdentifier) {
        this._cookiesService.set('machineIdentifier', this.connectedMachineIdentifier)
        this.connectionStatus = 'Connected';
        console.log('Connected to a machine : ', res);

        console.warn('Previous Transfers : ', res['previousTransfers']);

      }
    });

    this.SocketClientService.getJoinRequestAckStatus().subscribe((res) => {

      console.log('Joined the channel : ', res);
      if (Object.keys(res).length == 0) {
        return;
      } else {
        console.warn('******************************************JOIN ACK*************************************************************');
        console.warn('******************************************SENDING FIND ALL MACHINE*************************************************************');

        this.findAllDevices();
      }

    });

    this.SocketClientService.getMachineLogoutInformation().subscribe((res) => {

      console.log('machine logging out : ', res.machineIdentifier);

      const index = this.onlineMachineList.findIndex(({ machineIdentifier }) => machineIdentifier === res.machineIdentifier);
      if (index >= 0) {
        this.onlineMachineList.splice(index, 1);
        console.log('machine logged out : ', res);
        console.log(res.machineIdentifier + ' == ' + this.connectedMachineIdentifier);
      }

      if (res.machineIdentifier == this.connectedMachineIdentifier) {
        this.connectedMachineName = '';
        this.connectedMachineIdentifier = '';
        this.connectionStatus = 'Not Connected';
        this.inProgressTransferList = [];
      }

    });

    this.SocketClientService.getInProgressTransfers().subscribe((res: any) => {

      console.log('Transfer information received : ', res);

      if (Object.keys(res).length == 0) {
        return;
      }

      var obj = this.inProgressTransferList.filter(function (progress) {
        return progress.progress_identifier == res.progress_identifier;
      });

      console.log('In progress transfers ', this.inProgressTransferList);

      if ((obj.length == 0 || obj == null)) {
        this.inProgressTransferList.push(res);
        console.log(this.inProgressTransferList);
      } else {
        for (var i = 0; i < this.inProgressTransferList.length; i++) {

          if (this.inProgressTransferList[i].progress_identifier == res.progress_identifier) {
            this.inProgressTransferList[i].status = res.status;
            this.inProgressTransferList[i].progress = res.progress;
            this.inProgressTransferList[i].parent_hash_node_id = res.parent_hash_node_id;
            console.log('progress updated : ', this.inProgressTransferList[i]);
          }

        }
      }

    });


  }

  listLang: any = [
    { text: 'English', flag: 'assets/images/flags/us.jpg', lang: 'en' },
    { text: 'Spanish', flag: 'assets/images/flags/spain.jpg', lang: 'es' },
    { text: 'German', flag: 'assets/images/flags/germany.jpg', lang: 'de' },
    { text: 'Italian', flag: 'assets/images/flags/italy.jpg', lang: 'it' },
    { text: 'Russian', flag: 'assets/images/flags/russia.jpg', lang: 'ru' },
  ];

  openMobileMenu: boolean;

  @Output() settingsButtonClicked = new EventEmitter();
  @Output() mobileMenuButtonClicked = new EventEmitter();

  ngOnInit() {
    // this.initialAppState = initialState;
    this.store.select('layout').subscribe((data) => {
      this.theme = data.DATA_LAYOUT;
    })
    this.openMobileMenu = false;
    this.element = document.documentElement;

    this.cookieValue = this._cookiesService.get('lang');
    const val = this.listLang.filter(x => x.lang === this.cookieValue);
    this.countryName = val.map(element => element.text);
    if (val.length === 0) {
      if (this.flagvalue === undefined) { this.valueset = 'assets/images/flags/us.jpg'; }
    } else {
      this.flagvalue = val.map(element => element.flag);
    }
    //performing remember me operation
    if (localStorage.getItem('rememberMe')) {
      this.rememberMe = true;
    } else {
      this.rememberMe = false;
    }

    this.rememberMe ? this.storedAdminInfo = localStorage.getItem('storedAdminInfo') :
    this.storedAdminInfo = sessionStorage.getItem('storedAdminInfo');
    
    if (this.storedAdminInfo) {
      try {
        const decryptedData = this.encryptionService.decrypt(this.storedAdminInfo);
        if (decryptedData) {
          this.storedAdminInfo = JSON.parse(decryptedData);
          console.log('this.storedAdminInfo', this.storedAdminInfo)
        }
      } catch (e) {
        console.error('Error parsing stored admin info:', e);
      }
    }
   
    console.warn('******************************************SENDING JOIN REQUEST*************************************************************');
    console.warn('******************************************TOP BAR INITIATED*************************************************************');
    this.initiateSocketCommunication(this.storedAdminInfo.user_info_obj, this.storedAdminInfo.token, this.storedAdminInfo.session_identifier);

    const storedMode = this.storageService.get('mode');
    if (storedMode) {
      this.currentMode = storedMode;
      this.changeMode(this.currentMode);
    }

    // this.route.params.subscribe(params => {
    //   this.getUserInfo(params['userId'])
    //     .then(() => {
    //       this.getWorkspacesList();
    //     })
    //     .catch(error => {
    //       console.error("Failed to fetch user info or workspaces:", error);
    //     });
    // });
  }

  setLanguage(text: string, lang: string, flag: string) {
    this.countryName = text;
    this.flagvalue = flag;
    this.cookieValue = lang;
    this.languageService.setLanguage(lang);
  }

  /**
   * Toggles the right sidebar
   */
  toggleRightSidebar() {
    this.settingsButtonClicked.emit();
  }

  /**
   * Toggle the menu bar when having mobile screen
   */
  toggleMobileMenu(event: any) {
    event.preventDefault();
    this.mobileMenuButtonClicked.emit();
  }



  /**
   * Logout the user
   */
  logout() {
    if (!this.rememberMe) {
      // if (this.storedAdminInfo.role_name == 'Admin') {
      //   this.loginRoute = 'auth/user-login';
      //   sessionStorage.removeItem("storedAdminInfo");
      // } else if (this.storedAdminInfo.role_name == 'Owner') {
      //   this.loginRoute = 'auth/admin-login';
      //   sessionStorage.removeItem("storedAdminInfo");
      // }
      sessionStorage.removeItem("storedAdminInfo");
    } else {
      localStorage.removeItem("storedAdminInfo");
      // if (this.storedAdminInfo.role_name == 'Admin') {
      //   this.loginRoute = 'auth/user-login';
      //   localStorage.removeItem("storedAdminInfo");
      // } else if (this.storedAdminInfo.role_name == 'Owner') {
      //   this.loginRoute = 'auth/admin-login';
      //   localStorage.removeItem("storedAdminInfo");
      // }
    }
    this.loginRoute = 'auth/user-login';
    this.SocketClientService.disconnectSocketCommunication();
    this.router.navigate([this.loginRoute]);
  }

  /**
   * Fullscreen method
   */
  fullscreen() {
    document.body.classList.toggle('fullscreen-enable');
    if (
      !document.fullscreenElement && !this.element.mozFullScreenElement &&
      !this.element.webkitFullscreenElement) {
      if (this.element.requestFullscreen) {
        this.element.requestFullscreen();
      } else if (this.element.mozRequestFullScreen) {
        /* Firefox */
        this.element.mozRequestFullScreen();
      } else if (this.element.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        this.element.webkitRequestFullscreen();
      } else if (this.element.msRequestFullscreen) {
        /* IE/Edge */
        this.element.msRequestFullscreen();
      }
    } else {
      if (this.document.exitFullscreen) {
        this.document.exitFullscreen();
      } else if (this.document.mozCancelFullScreen) {
        /* Firefox */
        this.document.mozCancelFullScreen();
      } else if (this.document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        this.document.webkitExitFullscreen();
      } else if (this.document.msExitFullscreen) {
        /* IE/Edge */
        this.document.msExitFullscreen();
      }
    }
  }

  changeLayout(layoutMode: string) {
    this.theme = layoutMode;
    this.store.dispatch(changesLayout({ layoutMode }));
    this.store.select(getLayoutMode).subscribe((layout) => {
      document.documentElement.setAttribute('data-layout', layout)
    })
  }

  initiateSocketCommunication(userInfo, loginToken, sessionIdentifier) {

    this.SocketClientService.initiateSocketCommunication(userInfo, loginToken, sessionIdentifier);

  }

  findAllDevices() {

    this.SocketClientService.findAllMachinesRequest();

  }

  connectToAMachine(machineName, machineIdentifier) {
    this.SocketClientService.connectToMachineRequest(machineName, machineIdentifier);

  }

  disconnectFromMachine(machineName, machineIdentifier) {

    this.inProgressTransferList = [];
    this.connectedMachineName = '';
    this.connectionStatus = 'Not Connected';
    this.connectedMachineIdentifier = '';
    this._cookiesService.delete('machineIdentifier');
    this.SocketClientService.disconnectMachineRequest(machineName, machineIdentifier);
  }

  changeMode(mode: string) {
    this.store.dispatch(changeMode({ mode }));
    document.documentElement.setAttribute('data-bs-theme', mode);
    if (mode === 'light') {
      document.documentElement.setAttribute('data-topbar', 'dark');
    } else {
      document.documentElement.setAttribute('data-topbar', mode);
    }
    this.storageService.set('mode', mode);
  }
  
}