import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
    // {
    //     id: 1,
    //     label: 'MENUITEMS.MENU.TEXT',
    //     isTitle: true
    // },
    // {
    //     id: 2,
    //     label: 'MENUITEMS.DASHBOARDS.TEXT',
    //     icon: 'bx-home-circle',
    //     subItems: [
    //         {
    //             id: 3,
    //             label: 'MENUITEMS.DASHBOARDS.LIST.DEFAULT',
    //             link: '/dashboard',
    //             parentId: 2
    //         },
    //     ]
    // },
    {
        id: 8,
        isLayout: true
    },
    // {
    //     id: 9,
    //     label: 'MENUITEMS.APPS.TEXT',
    //     isTitle: true
    // },
    {
        id: 12,
        label: 'MENUITEMS.FILEMANAGER.TEXT',
        icon: 'bx-file',
        link: '/filemanager',
    },
    {
        id: 12,
        label: 'MENUITEMS.USERMANAGEMENT.TEXT',
        icon: 'bxs-user',
        link: '/user-list',
    },
];

