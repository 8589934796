<header id="page-topbar">
  <div class="navbar-header prevent-select">
    <div class="d-flex">
      <!-- LOGO -->
      <div class="navbar-brand-box">
        <a class="logo logo-dark" draggable="false">
          <span class="logo-sm">
            <!-- <img src="assets/images/Disney Advertising White (1).svg" alt="" height="22"> -->
            <img src="{{storedAdminInfo.client_logo_path}}" alt="" height="22" draggable="false">
          </span>
          <span class="logo-lg">
            <!-- <img src="assets/images/Disney Advertising White (1).svg" alt="" height="17"> -->
            <img src="{{storedAdminInfo.client_logo_path}}" alt="" height="17" draggable="false">
          </span>
        </a>

        <a class="logo logo-light" draggable="false">
          <span class="logo-sm">
            <!-- <img src="assets/images/Disney Advertising_Vertical Badge_White (2).svg" alt="" height="30"> -->
            <img src="{{storedAdminInfo.client_logo_path}}" alt="" height="30" draggable="false">
          </span>
          <span class="logo-lg">
            <!-- <img src="assets/images/Disney Advertising White (1).svg" alt="" height="30"> -->
            <img src="{{storedAdminInfo.client_logo_path}}" alt="" height="30" draggable="false">
          </span>
        </a>
      </div>

      <!-- <button type="button" class="btn btn-sm px-3 font-size-16 header-item"
        [ngClass]="{ 'd-none': theme === 'horizontal' }" id="vertical-menu-btn" (click)="toggleMobileMenu($event)">
        <i class="fa fa-fw fa-bars"></i>
      </button> -->

      <!-- App Search-->
      <!-- <form class="app-search d-none d-lg-block">
        <div class="position-relative">
          <input type="text" class="form-control" placeholder="{{'HEADER.SEARCH' | translate}}">
          <span class="bx bx-search-alt"></span>
        </div>
      </form> -->
    </div>

    <div class="d-flex">
      <!-- <div class="dropdown d-inline-block d-lg-none ms-2" dropdown>
        <button type="button" class="btn header-item noti-icon" id="page-header-search-dropdown" data-toggle="dropdown"
          aria-haspopup="true" dropdownToggle aria-expanded="false">
          <i class="mdi mdi-magnify"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0" *dropdownMenu
          aria-labelledby="page-header-search-dropdown">
          <form class="p-3">
            <div class="m-0">
              <div class="input-group">
                <input type="text" class="form-control" placeholder="Search ..." aria-label="Recipient's username">
                <div class="input-group-append">
                  <button class="btn btn-primary" type="submit"><i class="mdi mdi-magnify"></i></button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div> -->

      <!-- <div class="dropdown d-none d-lg-inline-block ms-1">
        <button type="button" class="btn header-item noti-icon waves-effect" data-bs-toggle="fullscreen"
          (click)="fullscreen()">
          <i class="bx bx-fullscreen"></i>
        </button>
      </div> -->
      <div class="dropdown d-none d-lg-inline-block display-flex-override ms-1" dropdown>
        <button type="button" class="btn btn-primary btn-label waves-light custom-style-btn"
          [ngClass]="{ 'bg-offline': connectionStatus === 'Not Connected', 'bg-connected': connectionStatus === 'Connected' }"
          dropdownToggle (click)="findAllDevices()">
          <i class="mdi mdi-remote-desktop label-icon"></i>

          <div class="d-flex align-items-center">
            @if(connectedMachineName){
            <span class="me-1">{{connectedMachineName}} - </span>
            <span class="">
              {{connectionStatus}}
            </span>
            } @else {
            <span class="">
              Disconnected
            </span>
            }

          </div>
        </button>
        <div class="dropdown-menu dropdown-menu-end show" *dropdownMenu>
          <div *ngIf="onlineMachineList.length == 0">
            <a class="dropdown-item" href="javascript: void(0);" draggable="false">
              <span class="ms-2">
                No machines online. Please install our desktop app and ensure you're logged in.
              </span>
            </a>
          </div>
          <h5 class="font-size-14 mt-2 ms-3 margin-common" *ngIf="onlineMachineList.length">Available Machines</h5>
          <div *ngFor="let machine of onlineMachineList">
            <a class="dropdown-item" href="javascript: void(0);"
              (click)="machine.machineIdentifier === connectedMachineIdentifier? disconnectFromMachine(machine.machineName, machine.machineIdentifier) : connectToAMachine(machine.machineName, machine.machineIdentifier)"
              draggable="false">
              {{ machine.machineName }}
              <span class="badge ms-2"
                [ngClass]="machine.machineIdentifier === connectedMachineIdentifier? 'bg-danger' : 'bg-secondary'">
                {{ machine.machineIdentifier === connectedMachineIdentifier? 'Disconnect' : 'Connect' }}
              </span>
            </a>
          </div>
        </div>
      </div>
      <!-- <div class="d-flex align-items-center">
        @if(connectedMachineName){
        <span class="text-success me-1">{{connectedMachineName}}</span>
        }
        <span class="badge me-1 p-2 font-size-full glow"
          [ngClass]="{ 'bg-offline': connectionStatus === 'Not Connected', 'bg-info': connectionStatus === 'Connected' }">
          {{connectionStatus}}
        </span>
      </div> -->

      <div class=" dropdown d-inline-block" dropdown>
        <button type="button" class="btn header-item d-flex align-items-center" dropdownToggle
          id="page-header-user-dropdown">
          <div>
            <p class="me-1 mb-0 text-end custom-style">Hello,</p>
            <span class="d-none d-xl-inline-block me-1">{{storedAdminInfo?.first_name}}{{' '}}
              {{storedAdminInfo?.last_name}}</span>
          </div>
          <img class="rounded-circle header-profile-user" src="assets/images/users/user-dummy-img.jpg"
            alt="Header Avatar" draggable="false">
        </button>
        <div class="dropdown-menu dropdown-menu-end" *dropdownMenu>
          <!-- item-->
          <!-- <a class="dropdown-item" routerLink="/contacts/profile"><i
              class="bx bx-user font-size-16 align-middle me-1"></i>
            {{ 'HEADER.LOGIN.PROFILE' | translate}}</a> -->
          <!-- <div class="dropdown-divider"></div> -->
          <li> <a class="dropdown-item text-danger" [routerLink]="['/edit-profile']" draggable="false"><i
                class="bx bx-user font-size-16 align-middle me-1 text-danger"></i>
              {{ 'HEADER.LOGIN.PROFILE' | translate}}</a></li>
          <li> <a class="dropdown-item text-danger" href="javascript: void(0);" (click)="logout()" draggable="false"><i
                class="bx bx-power-off font-size-16 align-middle me-1 text-danger"></i>
              {{ 'HEADER.LOGIN.LOGOUT' | translate}}</a></li>

        </div>
      </div>

      <!-- <div class="dropdown d-inline-block">
        <button type="button" class="btn header-item noti-icon right-bar-toggle" (click)="toggleRightSidebar()">
          <i class="bx bx-cog bx-spin"></i>
        </button>
      </div> -->
      <div class="dropdown d-inline-block">
        <button *ngIf="currentMode !== 'dark'" type="button" class="btn header-item noti-icon"
          (click)="changeMode('dark')">
          <i class="bx bxs-moon"></i>
        </button>
        <button *ngIf="currentMode === 'dark'" type="button" class="btn header-item noti-icon"
          (click)="changeMode('light')">
          <i class="bx bxs-sun"></i>
        </button>
      </div>

    </div>
  </div>
</header>