import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from "@angular/common/http";
import { Observable, BehaviorSubject } from 'rxjs';
import { io } from "socket.io-client"; // v4.1.3
import { environment } from '../../../environments/environment';
import { ToastrService } from 'ngx-toastr';

@Injectable({
    providedIn: 'root'
})

export class SocketClientService {

    userInfo: any = {};
    sessionIdentifier = '';
    currentConnectedMachineIdentifier = '';
    previousConnectedMachineIdentifier = '';
    currentConnectedMachineName = '';
    previousConnectedMachineName = '';
    // socket = null;
    loginToken = '';
    myIpAddress = '';
    machineLogoutInformation: any = new BehaviorSubject({});
    inProgressTransferList: any = new BehaviorSubject({});
    anyTransferCompleteNotification: any = new BehaviorSubject({});
    realTimeUpdatesForFilesAndFolders: any = new BehaviorSubject({});
    transferCancelResponse: any = new BehaviorSubject({});
    clearProgressResponse: any = new BehaviorSubject({});
    isSocketDisconnectedDueToNetworkFailure = false;

    public onlineMachine = new BehaviorSubject({});
    public connectedMachineIdentifier = new BehaviorSubject({});
    public joinRequestUpdate = new BehaviorSubject({});
    heartBeatInterval = null;
    public nodeCreationUpdate = new BehaviorSubject({});
    public playlistUpdate = new BehaviorSubject({});
    public thumbnailUpdate = new BehaviorSubject({});
    public coverImageUpdate = new BehaviorSubject({});

    socket = io(environment.socket_url);

    constructor(private http: HttpClient, private _toastrService: ToastrService) {

    }

    registerSocketCallBacks() {
        var self = this;

        this.socket.on('connect', function () {
            console.log('Connected to Server2');
            console.warn('connected to Server after a network failure :');
            self.heartBeatInterval = setInterval(() => {
                console.log('sending heartbeat : ', self.sessionIdentifier);
                self.socket.emit('heartbeat', self.sessionIdentifier);
            }, 3000);

            // if(self.isSocketDisconnectedDueToNetworkFailure == true) {
            //     self.connectToMachineRequest(self.previousConnectedMachineName, self.previousConnectedMachineIdentifier);
            //     self.isSocketDisconnectedDueToNetworkFailure = false;
            // }
        });

        this.socket.on('disconnect', function () {
            console.warn('Disconnected to Server due to network failure : connection lost : , ', self.currentConnectedMachineIdentifier);
            self.machineLogoutInformation.next({
                'machineIdentifier': self.currentConnectedMachineIdentifier
            })
            self.currentConnectedMachineIdentifier = '';
            console.warn('Disconnected to Server due to network failure : connection lost : , ', self.currentConnectedMachineIdentifier);
            self.isSocketDisconnectedDueToNetworkFailure = true;
        });

        this.socket.on('join_request_ack_' + this.sessionIdentifier,
            function (ipAddress) {

                if (self.myIpAddress == '') {

                    console.log('Received ack from server for join request and my ip is : ', ipAddress);
                    self.myIpAddress = ipAddress;

                    self.joinRequestUpdate.next({
                        'ip_address': ipAddress
                    });

                }


            });

        this.socket.on('find_all_machines_response_' + this.sessionIdentifier,
            function (deskUserInfo, machineName, machineIdentifier) {
                console.log('Received machine response ');

                self.onlineMachine.next({
                    'desktopUserInfo': deskUserInfo,
                    'machineName': machineName,
                    'machineIdentifier': machineIdentifier
                });

            });

        this.socket.on('connection_successfull_response_' + this.sessionIdentifier,
            function (deskUserInfo, machineName, machineIdentifier) {
                console.warn('Received connection successfull response from electron with identifier :  ' + machineIdentifier);
                self.currentConnectedMachineIdentifier = machineIdentifier;
                self.previousConnectedMachineIdentifier = machineIdentifier;
                self.currentConnectedMachineName = machineName;
                self.previousConnectedMachineName = machineName;
                let previousTransfers = [];
                if (deskUserInfo['previous_transfers'] != undefined) {
                    previousTransfers = deskUserInfo['previous_transfers'];
                    console.log(previousTransfers);
                }
                self.connectedMachineIdentifier.next({
                    'desktopUserInfo': deskUserInfo,
                    'machineName': machineName,
                    'machineIdentifier': machineIdentifier,
                    'previousTransfers': previousTransfers
                })
            });

        this.socket.on('desktop_user_logout_request' + this.userInfo.user_id,
            function (machineIdentifier) {
                console.log('Received log out request from a machine with identifier : ' + machineIdentifier);
                self.machineLogoutInformation.next({
                    'machineIdentifier': machineIdentifier
                })

                if (machineIdentifier == self.currentConnectedMachineIdentifier) {
                    self.currentConnectedMachineIdentifier = '';
                }
            });

        this.socket.on('machine_online_information_' + this.userInfo.user_id,
            function (userInfo, machineName, machineIdentifier, machineIpAddress) {

                if (self.myIpAddress == machineIpAddress) {
                    console.log('New machine online information : ' + machineIdentifier);
                    self.onlineMachine.next({
                        'desktopUserInfo': userInfo,
                        'machineName': machineName,
                        'machineIdentifier': machineIdentifier
                    });
                }
            });

        this.socket.on('data_transfer_update_' + this.sessionIdentifier,
            function (userInfo, sessionIdentifier, transferInfo) {
                console.warn('Transfer info : ');
                console.warn(transferInfo);

                console.log('progress : ' + transferInfo['progress']);
                console.log('status : ' + transferInfo['status']);

                self.inProgressTransferList.next({
                    'transfer_name': '',
                    'status': transferInfo['status'],
                    'progress': transferInfo['progress'],
                    'parent_hash_node_id': transferInfo['parent_hash_node_id'],
                    'progress_identifier': transferInfo['progress_identifier'],
                    'transfer_type': transferInfo['transfer_type'],
                    'failed_files': transferInfo['failed_files'],
                    'aspera_transfer_id': transferInfo['aspera_transfer_id'],
                    'transfer_speed': transferInfo['transfer_speed'],
                    'time_to_complete_transfer': transferInfo['time_to_complete_transfer'],
                    'pre_transfer_bytes': transferInfo['pre_transfer_bytes'],
                    'bytes_transferred': transferInfo['bytes_transferred'],
                    'failed_files_info': transferInfo['failed_files_info'],
                    'is_folder': ''
                });

                // if (transferInfo['status'] == 'FAILED') {

                //     self._toastrService.success('File Transfer Complete with errors!');

                // } else if (transferInfo['status'] == 'IN_PROGRESS') {

                // } else if (transferInfo['status'] == 'COMPLETED') {

                //     self._toastrService.success('File Transfer Complete.');

                // } else {

                // }


            });

        // this.socket.on('data_transfer_update_' + this.userInfo.user_id,
        //     function (userInfo, sessionIdentifier, transferInfo) {

        //         console.log(transferInfo);
        //         console.log('progress =======> ' + transferInfo['progress']);
        //         console.log('status =======> ' + transferInfo['status']);
        //         console.log('parent node id =======> ' + transferInfo['parent_hash_node_id']);
        //         console.log('transfer type =======> ' + transferInfo['transfer_type']);

        //         if ( (transferInfo['status'] == 'COMPLETED' || transferInfo['status'] == 'FAILED') && transferInfo['transfer_type'] == 'UPLOAD') {

        //             self.anyTransferCompleteNotification.next({
        //                 'hash_node_id': transferInfo['parent_hash_node_id']
        //             });

        //         }

        //     });

        this.socket.on('data_transfer_update_for_workspace_' + this.userInfo.default_workspace_id,
            function (userInfo, sessionIdentifier, transferInfo) {

                console.warn('%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%');
                console.log(transferInfo);
                console.log('progress =======> ' + transferInfo['progress']);
                console.log('status =======> ' + transferInfo['status']);
                console.log('parent node id =======> ' + transferInfo['parent_hash_node_id']);
                console.log('transfer type =======> ' + transferInfo['transfer_type']);
                console.warn('%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%');

                if ((transferInfo['status'] == 'COMPLETED' || transferInfo['status'] == 'FAILED') && transferInfo['transfer_type'] == 'UPLOAD') {

                    let nodes = [];

                    if (transferInfo['status'] == 'COMPLETED' && transferInfo['updated_nodes'] != undefined) {
                        nodes = transferInfo['updated_nodes'];
                    }

                    self.anyTransferCompleteNotification.next({
                        'hash_node_id': transferInfo['parent_hash_node_id'],
                        'updated_node_hash_ids': nodes
                    });

                }

            });

        this.socket.on('transfer_initiated_response_' + this.sessionIdentifier,
            function (userInfo, progressIdentifier, transferName, transferType, IsFolder) {

                self._toastrService.success('Starting file transfer. Please wait!');
                self.inProgressTransferList.next({
                    'transfer_name': transferName,
                    'status': 'NOT_STARTED',
                    'progress': '0',
                    'parent_hash_node_id': '',
                    'progress_identifier': progressIdentifier,
                    'transfer_type': transferType,
                    'failed_files': '0',
                    'transfer_speed': '0 Mbps',
                    'time_to_complete_transfer': '0',
                    'pre_transfer_bytes': 0,
                    'bytes_transferred': 0,
                    'failed_files_info': [],
                    'is_folder': IsFolder
                });

            });

        // Method to listen for the transfer cancel status
        this.socket.on('transfer_cancel_response_' + this.sessionIdentifier,
            function (cancelTransferResponse, asperaTransferId) {
                self.transferCancelResponse.next({
                    'transferCancelResponse': cancelTransferResponse,
                    'asperaTransferId': asperaTransferId,
                });

            });

        this.socket.on('clear_progress_info_response_' + this.sessionIdentifier,
            function (deleteResult) {

                console.warn('delete result => ', deleteResult);

                if (deleteResult == true) {
                    self._toastrService.success('Transfer cleared successfully');
                    self.clearProgressResponse.next({
                        'clear_success': deleteResult
                    });
                } else {
                    self._toastrService.error('There was an error clearing the progress. Please try again.');
                    self.clearProgressResponse.next({
                        'clear_success': deleteResult
                    });
                }

            });

        this.socket.on('real_time_update_on_folder_file_change_' + this.userInfo.default_workspace_id,
            function (fromUserInfo, nodeHashId, realTimeEvent, updatedNodes, destinationHashedNodeIdForMoveEvent) {

                console.warn('%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%');
                console.log('received a real time update of folder / file change');
                console.warn('%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%');
                console.log(fromUserInfo);
                console.log(nodeHashId);
                console.log(realTimeEvent);
                console.log(updatedNodes);
                console.log(self.userInfo);
                console.log(destinationHashedNodeIdForMoveEvent);

                if (fromUserInfo.user_id == self.userInfo.user_id) {
                    // Ignore
                    return;
                }

                if (realTimeEvent) {

                    self.realTimeUpdatesForFilesAndFolders.next({
                        'hash_node_id': nodeHashId,
                        'real_time_nodes_to_update': updatedNodes,
                        'real_time_event': realTimeEvent,
                        'destination_hashed_node_id': destinationHashedNodeIdForMoveEvent
                    });

                }

            });


        this.socket.on('node_creation_update_' + this.userInfo.default_workspace_id,
            function (userInfo, parentHashedNodeId, nodeInfo, overiddenHashedNodeIdsArray) {

                self.nodeCreationUpdate.next({
                    'parent_hash_node_id': parentHashedNodeId,
                    'real_time_nodes_to_update': nodeInfo,
                    'overridden_hashed_node_ids_array': overiddenHashedNodeIdsArray
                });

            });

        // Updated to node_media_update
        this.socket.on('node_media_update_' + this.userInfo.default_workspace_id, function (data) {
            console.log('Node media update received:', data);
            const { event_name, node_id, ...updateData } = data;

            switch (event_name) {
                case 'playlist_updated':
                    self.playlistUpdate.next({ node_id, media_convert_playlist_url: updateData.media_convert_playlist_url });
                    break;
                case 'thumbnail_updated':
                    self.thumbnailUpdate.next({ node_id, thumbnail_url: updateData.thumbnail_url });
                    break;
                case 'cover_image_updated':
                    self.coverImageUpdate.next({ node_id, cover_image_url: updateData.cover_image_url });
                    break;
                default:
                    console.warn('Unhandled node media update event:', event_name);
            }
        });
    }

    getUpdatedOnlineMachine() {

        return this.onlineMachine;

    }

    getCompletedTransfers() {

        return this.anyTransferCompleteNotification;

    }

    getRealTimeUpdatesForFilesAndFolders() {

        return this.realTimeUpdatesForFilesAndFolders;

    }

    getInProgressTransfers() {

        return this.inProgressTransferList;

    }

    getTransferCancelResponse() {
        console.log('this.sessionIdentifier =' + this.sessionIdentifier);
        return this.transferCancelResponse;

    }

    getJoinRequestAckStatus() {

        return this.joinRequestUpdate;

    }

    initiateSocketCommunication(userInfo, loginToken, sessionIdentifier) {
        this.userInfo = userInfo;
        this.sessionIdentifier = sessionIdentifier;
        this.loginToken = loginToken;

        if (this.myIpAddress == '') {
            this.registerSocketCallBacks();
            console.log(this.userInfo);
            console.warn('******************************************IP EMPTY SENDING JOIN REQUEST FROM SERVICE*************************************************************');
            this.socket.emit('join_request_from_web_app', userInfo, loginToken, sessionIdentifier);
        }
    }

    findAllMachinesRequest() {

        console.warn('sending find all machine request', this.userInfo, this.loginToken, this.myIpAddress, this.sessionIdentifier);
        this.socket.emit('find_all_machines_request', this.userInfo, this.loginToken, this.myIpAddress, this.sessionIdentifier);

    }

    connectToMachineRequest(machineName, machineIdentifier) {

        console.warn('sending connection_request request', this.userInfo, this.loginToken, this.myIpAddress, this.sessionIdentifier);
        this.socket.emit('connection_request',
            this.userInfo, this.loginToken, machineName, machineIdentifier, this.sessionIdentifier);

    }

    getConnectedMachineIdentifier() {

        return this.connectedMachineIdentifier;

    }

    getMachineLogoutInformation() {

        return this.machineLogoutInformation;

    }

    disconnectSocketCommunication() {

        this.socket.disconnect();
        if (this.heartBeatInterval != null) {
            clearInterval(this.heartBeatInterval);
        }

    }

    disconnectMachineRequest(machineName, machineIdentifier) {

        this.socket.emit('disconnect_request_from_web',
            this.userInfo, this.loginToken, machineName, machineIdentifier, this.sessionIdentifier);

        this.currentConnectedMachineIdentifier = '';

    }

    sendOpenFilePickerRequestToConnectedMachine(currentNodeId) {
        this.userInfo['current_hash_node_id'] = currentNodeId;
        if (this.currentConnectedMachineIdentifier != '') {
            this.socket.emit('open_file_picker', this.userInfo, this.loginToken, this.currentConnectedMachineIdentifier, this.sessionIdentifier);

        } else {

            this._toastrService.error('Please connect to a machine');

        }

    }

    // Method to initiate cancel transfer
    sendCancelTransferRequestToConnectedMachine(cancelTransferInfo) {
        this.userInfo['cancelTransferInfo'] = cancelTransferInfo;
        this.socket.emit('cancel_transfer', this.userInfo, this.loginToken, this.currentConnectedMachineIdentifier, this.sessionIdentifier);
    }

    sendOpenFolderPickerRequestToConnectedMachine(currentNodeId) {
        this.userInfo['current_hash_node_id'] = currentNodeId;
        if (this.currentConnectedMachineIdentifier != '') {
            this.socket.emit('open_folder_picker', this.userInfo, this.loginToken, this.currentConnectedMachineIdentifier, this.sessionIdentifier);

        } else {

            this._toastrService.error('Please connect to a machine');

        }

    }

    sendDownloadRequest(nodeInfo) {

        if (this.currentConnectedMachineIdentifier != '') {
            this.socket.emit('download_request', this.userInfo, this.loginToken, this.currentConnectedMachineIdentifier, this.sessionIdentifier, nodeInfo);

        } else {

            this._toastrService.error('Please connect to a machine');

        }

    }

    clearProgressInfo(deleteFlag, transferInfo) {

        if (this.currentConnectedMachineIdentifier != '') {

            this.socket.emit('clear_progress_info', this.userInfo, this.loginToken, this.currentConnectedMachineIdentifier, this.sessionIdentifier, deleteFlag, transferInfo);

        } else {

            this._toastrService.error('Please connect to a machine');

        }

    }

    getClearProgressResponse() {
        console.log('this.sessionIdentifier =' + this.sessionIdentifier);
        return this.clearProgressResponse;

    }

    getPreviousTransfers() {

        // console.warn('sending connection_request request');
        // if (this.currentConnectedMachineIdentifier != '' && this.currentConnectedMachineName != '' && this.sessionIdentifier)
        //     this.socket.emit('connection_request',
        //         this.userInfo, this.loginToken, this.currentConnectedMachineName, this.currentConnectedMachineIdentifier, this.sessionIdentifier);

    }

    sendRealTimeUpdatesForOtherUsers(nodeHashId, realTimeEvent, updatedNodes, destinationHashedNodeIdForMoveEvent) {

        if (realTimeEvent != '') {

            this.socket.emit('real_time_update_on_folder_file_change', this.userInfo, nodeHashId, this.loginToken, realTimeEvent, updatedNodes, destinationHashedNodeIdForMoveEvent);

        } else {

            this._toastrService.error('Unrecognized Event!');

        }

    }

    sendAppRestartRequestToConnectedMachine() {

        if (this.currentConnectedMachineIdentifier != '') {
            this.socket.emit('app_restart_request', this.userInfo, this.loginToken, this.currentConnectedMachineIdentifier, this.sessionIdentifier);
        }

    }

    getNodeCreationUpdate() {

        return this.nodeCreationUpdate;

    }

    getPlaylistUpdate(): Observable<any> {
        return this.playlistUpdate.asObservable();
    }

    getThumbnailUpdate(): Observable<any> {
        return this.thumbnailUpdate.asObservable();
    }

    getCoverImageUpdate(): Observable<any> {
        return this.coverImageUpdate.asObservable();
    }

}
