<!-- breadcrumb item -->
<div class="row">
  <div class="col-12">
    <div class="page-title-box d-sm-flex align-items-center">
      <h4 class="mb-0 font-size-18 no-caps">{{ title }}</h4>
      <div class="d-flex page-title-right justify-content-between" style="margin-left: 14.5%;">
        <ol class="breadcrumb m-0">
          @for(item of breadcrumbItems;track $index){
          <ng-container>
            @if(item.active){
            <li class="breadcrumb-item active">{{ item.label }}
            </li>
            }@else{
            <li class="breadcrumb-item"><a href="javascript: void(0);" (click)="navigateTo(item.url)">{{ item.label
                }}</a>
            </li>}
          </ng-container>
          }
        </ol>
      </div>
    </div>
  </div>
</div>