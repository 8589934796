import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MenuActiveService {
  private isFileManagerActive = new BehaviorSubject<boolean>(false);
  private isUserManagementActive = new BehaviorSubject<boolean>(false);

  isFileManagerActive$ = this.isFileManagerActive.asObservable();
  isUserManagementActive$ = this.isUserManagementActive.asObservable();

  constructor(private router: Router) {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.updateActiveStates(event.url);
      });
  }

  private updateActiveStates(url: string) {
    // Check if the URL starts with '/filemanager/' or shared_with_me
    this.isFileManagerActive.next(url.startsWith('/filemanager/') || url.startsWith('/shared-with-me'));

    // Check if the URL starts with '/user/' and is one of the specified paths
    this.isUserManagementActive.next(
      url === '/user/user-list' ||
      url === '/user/invite-user' ||
      url.startsWith('/user/edit-user/')
    );
  }
}