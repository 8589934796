import { Injectable } from "@angular/core";
import { HttpService } from "./http.service";
import { map } from "rxjs/operators";
import { BehaviorSubject } from "rxjs";

@Injectable({ providedIn: 'root' })
export class ClientUserService {
  constructor(private httpService: HttpService) { }

  updateUserSessionInfo: any = new BehaviorSubject({});

  Login(login_info) {
    return this.httpService.user_login(login_info).pipe(map(res => res));
  }
  UserList(form_info) {
    return this.httpService.user_list(form_info).pipe(map(res => res));
  }
  RoleList() {
    return this.httpService.role_list().pipe(map(res => res));
  }
  AddNewUser(form_info) {
    return this.httpService.invite_new_user(form_info).pipe(map(res => res));
  }
  EditUser(form_info) {
    return this.httpService.EditUser(form_info).pipe(map(res => res));
  }
  verifyPasswordSetToken(token_info) {
    return this.httpService.verifyPasswordSetToken(token_info)
      .pipe(map(
        res => res
      ));
  }
  verifyPasswordResetToken(token_info) {
    return this.httpService.verifyPasswordResetToken(token_info)
      .pipe(map(
        res => res
      ));
  }
  setPassword(password_details) {
    return this.httpService.setPassword(password_details)
      .pipe(map(
        res => res
      ));
  }
  resetPassword(password_details) {
    return this.httpService.resetPassword(password_details)
      .pipe(map(
        res => res
      ));
  }
  fetchNodesList(nodes_data) {
    return this.httpService.fetchNodesList(nodes_data)
      .pipe(map(
        res => res
      ));
  }
  fetchWorkspaces() {
    return this.httpService.fetchWorkspaces()
      .pipe(map(
        res => res
      ));
  }
  fetchWorkspaceChildNodesList(node_id) {
    return this.httpService.fetchWorkspaceChildNodesList(node_id)
      .pipe(map(
        res => res
      ));
  }
  fetchParentChildNodes(node_id) {
    return this.httpService.fetchParentChildNodes(node_id)
      .pipe(map(
        res => res
      ));
  }
  fetchParentChildNodesList(hashed_node_id) {
    return this.httpService.fetchParentChildNodesList(hashed_node_id)
      .pipe(map(
        res => res
      ));
  }
  fetchRootNode() {
    return this.httpService.fetchRootNode()
      .pipe(map(
        res => res
      ));
  }
  inviteAccept(acceptInviteData) {
    return this.httpService.inviteAccept(acceptInviteData)
      .pipe(map(
        res => res
      ));
  }
  createFolder(folderData) {
    return this.httpService.createFolder(folderData)
      .pipe(map(
        res => res
      ));
  }
  moveNode(folderData) {
    return this.httpService.moveNode(folderData)
      .pipe(map(
        res => res
      ));
  }
  renameNode(folderData) {
    return this.httpService.renameNode(folderData)
      .pipe(map(
        res => res
      ));
  }
  fetchUserInfo(user_id) {
    return this.httpService.fetchUserInfo(user_id)
      .pipe(map(
        res => res
      ));
  }

  getFailedFilesInformation(aspera_transfer_id) {
    return this.httpService.getFailedFilesInformation(aspera_transfer_id)
      .pipe(map(
        res => res
      ));

  }

  deleteNode(folderData) {
    return this.httpService.deleteNode(folderData)
      .pipe(map(
        res => res
      ));
  }
  clientLogo(info) {
    return this.httpService.clientLogo(info)
      .pipe(map(
        res => res
      ));
  }
  intiateForgotPassword(info) {
    return this.httpService.intiateForgotPassword(info)
      .pipe(map(
        res => res
      ));
  }
  fetchListofRestrictedUserAccess(info) {
    return this.httpService.fetchListofRestrictedUserAccess(info)
      .pipe(map(
        res => res
      ));
  }
  createRestrictedAccess(info) {
    return this.httpService.createRestrictedAccess(info)
      .pipe(map(
        res => res
      ));
  }
  removeRestrictedAccess(info) {
    return this.httpService.removeRestrictedAccess(info)
      .pipe(map(
        res => res
      ));
  }
  updateRestrictedAccess(info) {
    return this.httpService.updateRestrictedAccess(info)
      .pipe(map(
        res => res
      ));
  }
  fetchSharedWithMeNodes() {
    return this.httpService.fetchSharedWithMeNodes()
      .pipe(map(
        res => res
      ));
  }
  fetchRestrictedAccessChildNodesList(info) {
    return this.httpService.fetchRestrictedAccessChildNodesList(info)
      .pipe(map(
        res => res
      ));
  }
  resend_invite(formData: FormData) {
    return this.httpService.resend_invite(formData).pipe(map(res => res));
  }
  cancel_invite(formData: FormData) {
    return this.httpService.cancel_invite(formData).pipe(map(res => res));
  }

  EditProfile(form_info) {
    return this.httpService.EditProfile(form_info).pipe(map(res => res));
  }


  getUserSessionInfo() {

    return this.updateUserSessionInfo;

  }

  setUserSessionInfo(sessionInfo) {

    this.updateUserSessionInfo.next({
      'session_info': sessionInfo
    });

  }

  deleteUser(form_info) {
    return this.httpService.deleteUser(form_info).pipe(map(res => res));
  }

  unBlockUser(form_info) {
    return this.httpService.unBlockUser(form_info).pipe(map(res => res));
  }

  fetchUserProfileInfo() {
    return this.httpService.fetchUserProfileInfo().pipe(map(res => res));
  }
  EditUserProfile(form_info) {
    return this.httpService.EditUserProfile(form_info).pipe(map(res => res));
  }
}
