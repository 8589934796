import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

// Auth Services
import { environment } from '../../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import { EncryptionService } from '../services/encryption.service';

@Injectable({ providedIn: 'root' })
export class AdminOrOwnerAuthGuard implements CanActivate {
    constructor(
        private router: Router, private toastr: ToastrService, private encryptionService: EncryptionService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

        let rememberMe: boolean;
        let storedAdminInfo: any;

        if (localStorage.getItem('rememberMe')) {
            rememberMe = true;
        } else {
            rememberMe = false;
        }

        rememberMe ? storedAdminInfo = localStorage.getItem('storedAdminInfo') :
            storedAdminInfo = sessionStorage.getItem('storedAdminInfo');

        // console.log('authGuard storedAdminInfo', storedAdminInfo);
        // storedAdminInfo ? storedAdminInfo = JSON.parse(storedAdminInfo) : storedAdminInfo = {};
        if (storedAdminInfo) {
            try {
                const decryptedData = this.encryptionService.decrypt(storedAdminInfo);
                if (decryptedData) {
                    storedAdminInfo = JSON.parse(decryptedData);
                    if (storedAdminInfo.role_name == 'Admin' || storedAdminInfo.role_name == 'Owner') {
                        return true;
                    }
                }
            } catch (e) {
                console.error('Error parsing stored admin info:', e);
            }
        }

        // not logged in so redirect to login page with the return url
        // console.log('in authguard if stored admin info not present');
        this.toastr.error('Permission needed');
        this.router.navigate(['auth/user-login']);
        return false;
    }
}
