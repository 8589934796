export const environment = {
  production: true,
  defaultauth: 'fakebackend',
  firebaseConfig: {
    apiKey: '',
    authDomain: '',
    databaseURL: '',
    projectId: '',
    storageBucket: '',
    messagingSenderId: '',
    appId: '',
    measurementId: ''
  },
  apiUrl: "https://dev-api.fastband-dev.io/api/",
  forgotPaswordMailUrl: "https://fastband-dev.io/",
  user_setup_link: "https://fastband-dev.io/auth/user-setup-password/",
  socket_url: "https://socket.fastband-dev.io",
  user_reset_link: "https://fastband-dev.io/auth/user-reset-password/",
};
