<div id="layout-wrapper" style="height: 100vh;">
  <app-topbar (settingsButtonClicked)="onSettingsButtonClicked()"
    (mobileMenuButtonClicked)="onToggleMobileMenu()"></app-topbar>
  <app-horizontaltopbar></app-horizontaltopbar>

  <div class="main-content">
    <div class="page-content">
      <router-outlet></router-outlet>
    </div>
  </div>

  <app-footer></app-footer>
  <!-- <app-rightsidebar></app-rightsidebar> -->
</div>