<footer class="footer prevent-select" style="padding: 10px 18px;height: 40px;">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-6">
                {{year}} © FastBand
            </div>
            <div class="col-sm-6">
                <div class="text-sm-end d-none d-sm-block">
                </div>
            </div>
        </div>
    </div>
</footer>