import { ActionReducerMap } from "@ngrx/store";
import { FilemanageReducer, FilemanagerState } from "./filemanager/filemanager.reducer";
import { LayoutState, layoutReducer } from "./layouts/layouts.reducer";

export interface RootReducerState {
    layout: LayoutState;
    Filelist: FilemanagerState;
}

export const rootReducer: ActionReducerMap<RootReducerState> = {
    layout: layoutReducer,
    Filelist: FilemanageReducer
}